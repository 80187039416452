import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { getPages, Link, safePrefix } from "../utils";
import getDateFromIso from "../utils/getDateFromIso";
import htmlToReact from "../utils/htmlToReact";
import Helmet from 'react-helmet'

export default class Blog extends React.Component {
  render() {
    let display_posts = _.orderBy(
      getPages(this.props.pageContext.pages, "/artykuly"),
      "frontmatter.date",
      "desc"
    );
    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post page post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title")}
                </h1>
              </header>
              {_.get(this.props, "pageContext.frontmatter.img_path") && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(this.props, "pageContext.frontmatter.img_path")
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.title")}
                  />
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
            </article>
          </div>
          <div className="inner">
            <div className="post-feed">
              {_.map(display_posts, (post, post_idx) => (
                <BlogElement post={post} key={post_idx} />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}


export const BlogElement = ({post}) => {
  return <article className="post post-card">
    <div className="post-card-inside">
      {_.get(post, "frontmatter.thumb_img_path") && (
        <Link
          className="post-card-thumbnail"
          to={safePrefix(_.get(post, "url"))}
        >
          <img
            className="thumbnail"
            src={safePrefix(
              _.get(post, "frontmatter.thumb_img_path")
            )}
            alt={_.get(post, "frontmatter.title")}
            loading="lazy"
          />
        </Link>
      )}
      <div className="post-card-content">
        <header className="post-header">
          <h2 className="post-title">
            <Link
              to={safePrefix(_.get(post, "url"))}
              rel="bookmark"
            >
              {_.get(post, "frontmatter.title")}
            </Link>
          </h2>
        </header>


        <div className="post-excerpt">
          <p>{_.get(post, "frontmatter.excerpt")}</p>
        </div>
        <footer className="post-meta">
          <div>{_.get(post, 'frontmatter.author')}</div>
          <time
            className="published"
            dateTime={_.get(post, "frontmatter.date")}
          >
            {getDateFromIso(_.get(post, "frontmatter.date"))}
          </time>
        </footer>
      </div>
    </div>
  </article>
}